<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <div class="d-flex align-items-center">
              <div v-if='back' class="arrow-nav mr-2" @click.prevent="$router.push('nomina/dashboard')">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1024 1024">
                  <path
                    d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0" />
                </svg>
              </div>
              <h2 class="card-title"><b>Historial de procesos</b></h2>
            </div>
          </template>
          <div class="text-right px-4 mt-3">
            <!--   -->
            <!-- <b-button v-b-modal.my-modal @click="infoForPerson(item)">Ver más</b-button> -->
            <!-- <button @click="$router.push('/helex/liquidacion_agregar')" class="mx-1 btn btn-success"><i class="fas fa-plus"></i>Nuevo período de liquidación</button> -->
            <!-- <button @click="$router.push({ name: 'NominaProceso'})" class="mx-1 btn btn-success"><i class="fas fa-plus"></i>Nuevo período de liquidación</button> -->
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="datatable_nomina" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Período de nómina</th>
                  <th>Tipo liquidación</th>
                  <th>Salarios</th>
                  <th>Pagos adicionales</th>
                  <th>Deducciones</th>
                  <th>Total a pagar</th>
                  <!-- <th>Total costo</th> -->
                  <th>En TransM.</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>
                    {{ $moment.tz(item.fechaInicio, "America/Costa_Rica").format("DD-MM-YYYY") + " / " +
                      $moment.tz(item.fechaFin,
                        "America/Costa_Rica").format("DD-MM-YYYY") }}
                  </td>
                  <td>
                    {{
                      item.tipoLiquidacion != null
                        ? item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2
                          ? "Prima Servicios"
                          : item.tipoLiquidacion == "3"
                            ? "Nomina"
                            : item.tipoLiquidacion == "4"
                              ? "Cesantías fin de año"
                              : "--"
                        : "---"
                    }}
                  </td>
                  <td>{{ item.totalSalarios != null ? item.totalSalarios.toLocaleString("es-CO", {
                    style: "currency",
                    currency:
                      "COP"
                  }) : "$ 0.00" }}</td>
                  <td>
                    {{ item.totalPagosAdicionales != null ? item.totalPagosAdicionales.toLocaleString("es-CO", {
                      style:
                        "currency", currency: "COP"
                    }) : "$ 0.00" }}
                  </td>
                  <td>{{ item.totalDeducciones != null ? item.totalDeducciones.toLocaleString("es-CO", {
                    style:
                      "currency",
                    currency: "COP"
                  }) : "$ 0.00" }}</td>
                  <td>{{ item.totalAPagar != null ? item.totalAPagar.toLocaleString("es-CO", {
                    style: "currency",
                    currency: "COP"
                  }) : "$ 0.00" }}</td>
                  <!-- <td>{{ item.totalCostos != null ? item.totalCostos.toLocaleString("es-CO", { style: "currency", currency: "COP" }) : "$ 0.00" }}</td> -->
                  <td class="">
                    <div class="d-flex justify-content-center">

                      <vs-button circle v-if="item.liquidado" icon success>
                        <i class="fas fa-solid fa-check-circle "></i>
                      </vs-button>
                      <vs-button icon danger v-else>
                        <i class="fas fa-solid fa-times-circle "></i>
                      </vs-button>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-content-center px-0 mx-0">

                      <vs-button success icon class="px-0" @click="
                        item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2 || item.tipoLiquidacion == 4 || item.tipoLiquidacion == 5 || item.tipoLiquidacion == 6
                          ? $router.push('/helex/liquidacion_editarv2/' + item.id)
                          : item.liquidado ? $router.push({ name: 'NominaTransmitir', params: { liquidacionId: item.id } }) : $router.push({ name: 'NominaPreparar', params: { liquidacionId: item.id } })
                        "><i class="fas fa-solid fa-pen "></i>
                      </vs-button>
                      <vs-button @click="deleteRow(item.id)" icon danger>
                        <i class="fas fa-solid fa-trash"></i>
                      </vs-button>

                    </div>


                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { core } from "../../../../config/pluginInit";
  const Swal = require("sweetalert2");

  export default {
    name: "DataTable",
    props: {
      back: {
        type: Boolean,
        default: true,
        required: false,
      },
    },
    data() {
      return {
        listaItems: [{}],
        usuario: {},
      };
    },
    async mounted() {
      try {
        this.$isLoading(true);
        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
        await this.getData();
        core.index();
        window.$("#datatable_departamentos").DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    methods: {
      async deleteRow(id) {
        const nominaEliminar = await this.$store.dispatch("hl_get", {
          path: "LiquidadorNomina/GetDataLiquidacionEliminar/" + id,
        });
        if (this.usuario.empresa.usarAportesEnLinea) {
          Swal.fire({
            title: "Seguro eliminar registro?",
            text: "Después de eliminarlo no podrás recuperarlo!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si!",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$isLoading(true);
              await this.$store.dispatch("hl_delete", {
                path: "NominaLiquidacion/DelNominaLiquidacion/" + id,
              });
              this.$isLoading(false);

              await this.getData();

              Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
            }
          });
        } else if (this.usuario.empresa.usarAportesEnLinea === false) {
          Swal.fire({
            title: "Seguro eliminar registro?",
            text: "Después de eliminarlo no podrás recuperarlo!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si!",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$isLoading(true);
              const { liquidacionAjuste } = nominaEliminar;
              liquidacionAjuste.trabajador.forEach(nominaDelete => {
                nominaDelete.tipoNota = "2";
              });
              const response = await this.$store.dispatch('hl_post_xml', {
                path: 'NominaIndividual/NominaIndividualDeAjusteEliminar',
                data: nominaEliminar
              })

              console.log("se eliminar con la Dian", response);

              await this.$store.dispatch("hl_delete", {
                path: "NominaLiquidacion/DelNominaLiquidacion/" + id,
              });
              
              await this.getData();
              
              this.$isLoading(false);
              Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
            }
          });
        }
      },
      async getData() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "NominaLiquidacion/GetNominaLiquidacion/" + this.usuario.empresa.id,
          });
          console.log("respuesta liquidacion", res);
          if (res.length > 0) {
            this.listaItems = res;
          } else {
            this.listaItems = [];
          }
        } catch (error) {
          this.listaItems = [];
          console.log("err", error);
        }
      },
    },
  };
</script>
